<template>
  <div>
    <b-overlay
      :show="$apollo.loading || mutationLoading"
      spinner-variant="primary"
    >
      <!-- search input -->
      <div class="custom-search d-flex justify-content-between">
        <b-form-group
          class="form-inline"
          label="Search"
          label-size="sm"
        >
          <b-form-input
            v-model="searchTerm"
            class="d-inline-block mr-1"
            placeholder="Search request"
            type="text"
          />
          <b-button
            v-b-modal.addRequestModalp
            variant="primary"
          >
            <feather-icon icon="PlusIcon" />
            Add Request
          </b-button>
          <b-form-checkbox
            v-model="hideClosed"
            class="d-flex align-items-center mt-1 justify-content-start"
          >
            Hide Closed Requests
          </b-form-checkbox>
        </b-form-group>
        <b-form-group
          label="Show requests raised by"
          label-for="btn-radios-1"
          label-size="sm"
        >
          <b-form-radio-group
            id="btn-radios-1"
            v-model="requester"
            :options="[
              { text: 'Partner & Incubator', value: null },
              { text: 'Incubator', value: 'Incubator' },
              {text:'Partner',value:'Partner'},
            ]"
            buttons
            size="sm"
            button-variant="light"
          />
        </b-form-group>
      </div>

      <!-- table -->
      <vue-good-table
        :columns="columns"
        :pagination-options="{
          enabled: true,
          perPage:pageLength
        }"
        :rows="filteredRows"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm }"
      >
        <template
          slot="table-row"
          slot-scope="props"
        >

          <span
            v-if="props.column.field === 'title'"
            class="d-flex justify-content-between align-items-center"
          >
            {{ props.row.title }}
            <b-button
              variant="link"
              size="sm"
              @click="selectedRow = props.row.originalIndex; $bvModal.show('requestDetailsModalp')"
            >View</b-button>
          </span>
          <span v-else-if="props.column.field === 'priority'">
            <b-badge
              :variant="statusVariant(props.formattedRow[props.column.field])"
            >{{ props.formattedRow[props.column.field] }}</b-badge>
          </span>
          <span
            v-else-if="props.column.id === 'assigned_to'"
            class="d-flex justify-content-between align-items-center"
          >
            {{ props.formattedRow[props.column.field] }}
            <!--            <b-button-->
            <!--              v-if="!props.row.assignee_id"-->
            <!--              variant="outline-primary"-->
            <!--              size="sm"-->
            <!--              @click="selectedRow = props.row.originalIndex; $bvModal.show('assignModal')"-->
            <!--            >Assign</b-button>-->
          </span>
          <span
            v-else-if="props.column.field === 'assignee_status'"
          >
            <b-badge
              class="text-capitalize"
              :variant="statusVariant(`${props.formattedRow[props.column.field]}`.toLowerCase())"
            >{{ props.formattedRow[props.column.field] }}</b-badge>
            <b-dropdown
              v-if="!props.row.raisedbyincubator"
              size="sm"
              toggle-class="text-decoration-none"
              variant="link"
              class="px-0"
              no-caret
            >
              <template v-slot:button-content>
                <span class="align-middle text-nowrap">Update <feather-icon
                  class="align-middle ml-25"
                  icon="ChevronDownIcon"
                /></span>
              </template>
              <b-dropdown-item
                variant="primary"
                :disabled="props.row.assignee_status === 'in-process'"
                @click="updateRequestAssigneeStatus(props.row.requesttable_id, 'in-process')"
              >
                <feather-icon
                  class="mr-50"
                  icon="ClockIcon"
                />
                <span>In-Process</span>
              </b-dropdown-item>
              <b-dropdown-item
                variant="success"
                :disabled="props.row.assignee_status === 'resolved'"
                @click="updateRequestAssigneeStatus(props.row.requesttable_id, 'resolved')"
              >
                <feather-icon
                  class="mr-50"
                  icon="CheckIcon"
                />
                <span>Resolved</span>
              </b-dropdown-item>
              <b-dropdown-item
                variant="danger"
                :disabled="props.row.assignee_status === 'closed'"
                @click="updateRequestAssigneeStatus(props.row.requesttable_id, 'closed')"
              >
                <feather-icon
                  class="mr-50"
                  icon="XIcon"
                />
                <span>Closed</span>
              </b-dropdown-item>

            </b-dropdown>
          </span>

          <span
            v-else-if="props.column.field === 'status'"
          >
            <b-badge
              :variant="statusVariant(props.formattedRow[props.column.field].toLowerCase())"
            >{{ props.formattedRow[props.column.field] }}</b-badge>
            <b-dropdown
              v-if="props.row.raisedbyincubator"
              size="sm"
              toggle-class="text-decoration-none"
              variant="link"
              class="px-0"
              no-caret
            >
              <template v-slot:button-content>
                <span class="align-middle text-nowrap">Update <feather-icon
                  class="align-middle ml-25"
                  icon="ChevronDownIcon"
                /></span>
              </template>
              <b-dropdown-item
                variant="primary"
                :disabled="props.row.status === 'in-process'"
                @click="updateRequestStatus(props.row.requesttable_id, 'in-process')"
              >
                <feather-icon
                  class="mr-50"
                  icon="ClockIcon"
                />
                <span>In-Process</span>
              </b-dropdown-item>
              <b-dropdown-item
                variant="success"
                :disabled="props.row.status === 'resolved'"
                @click="updateRequestStatus(props.row.requesttable_id, 'resolved')"
              >
                <feather-icon
                  class="mr-50"
                  icon="CheckIcon"
                />
                <span>Resolved</span>
              </b-dropdown-item>
              <b-dropdown-item
                variant="danger"
                :disabled="props.row.status === 'closed'"
                @click="updateRequestStatus(props.row.requesttable_id, 'closed')"
              >
                <feather-icon
                  class="mr-50"
                  icon="XIcon"
                />
                <span>Closed</span>
              </b-dropdown-item>

            </b-dropdown>
          </span>
          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template
          slot="pagination-bottom"
          slot-scope="props"
        >
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap ">
                Showing 1 to
              </span>
              <b-form-select
                v-model="pageLength"
                :options="['3','5','10']"
                class="mx-1"
                @input="(value)=>props.perPageChanged({currentPerPage:value})"
              />
              <span class="text-nowrap"> of {{ props.total }} entries </span>
            </div>
            <div>
              <b-pagination
                :per-page="pageLength"
                :total-rows="props.total"
                :value="1"
                align="right"
                class="mt-1 mb-0"
                first-number
                last-number
                next-class="next-item"
                prev-class="prev-item"
                @input="(value)=>props.pageChanged({currentPage:value})"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
      <b-modal
        id="addRequestModalp"
        no-close-on-backdrop
        size="lg"
        title="Add Request"
        :ok-title="`${modalstage == 1 ? 'Next' : 'Add'}`"
        @ok="addRequest"
      >
        <b-row :class="`${this.modalstage === 1 ? '' : 'd-none'}`">
          <b-col md="12">
            <b-row class="px-1 mb-2">
              <b-col
                md="6"
                :class="tab==1?'selected-top-tab':'deselected-top-tab'"
                @click="tab=1;fetchtype = 'accept';$apollo.queries.startups.refetch()"
              >
                <span>Onboarded</span>
              </b-col>
              <b-col
                md="6"
                :class="tab==2?'selected-top-tab':'deselected-top-tab'"
                @click="tab=2;fetchtype = null;$apollo.queries.startups.refetch()"
              >
                <span>Network</span>
              </b-col>
            </b-row>
          </b-col>
          <b-col md="12">
            <div class="custom-search d-flex justify-content-start">
              <b-form-group
                class="form-inline"
                label="Search"
                label-size="sm"
              >
                <b-form-input
                  v-model="startupsearchTerm"
                  class="d-inline-block mr-1"
                  placeholder="Search Partner"
                  type="text"
                />
                <!-- <b-button
                  variant="primary"
                >
                  Select All
                </b-button> -->
              </b-form-group>
            </div>
            <vue-good-table
              id="startuptable"
              :columns="startup_columns"
              :pagination-options="{
                enabled: true,
                perPage:pageLength
              }"
              :rows="startups"
              :select-options="{ enabled: true }"
              :search-options="{
                enabled: true,
                externalQuery: startupsearchTerm }"
              @on-selected-rows-change="selectionChanged"
            >
              <template
                slot="table-row"
                slot-scope="props"
              >
                <span
                  v-if="props.column.field === 'website'"
                  :id="'row-' + props.row.id"
                >
                  <b-button
                    v-if="props.row.users_organizationtable.url"
                    variant="primary"
                    size="sm"
                    target="_blank"
                    :href="prependHttps(props.row.users_organizationtable.url)"
                  >
                    Visit
                  </b-button>
                </span>
                <!-- <span
                  v-else-if="props.column.field === 'action'"
                >
                  <b-form-checkbox
                    :checked="selectedStartups.indexOf(props.row.id) >= 0"
                    @change="togglestartups(props.row.id)"
                    inline
                  >
                  </b-form-checkbox>
                </span> -->
                <!-- Column: Common -->
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>

              <!-- pagination -->
              <template
                slot="pagination-bottom"
                slot-scope="props"
              >
                <div class="d-flex justify-content-between flex-wrap">
                  <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap ">
                      Showing 1 to
                    </span>
                    <b-form-select
                      v-model="pageLength"
                      :options="['3','5','10']"
                      class="mx-1"
                      @input="(value)=>props.perPageChanged({currentPerPage:value})"
                    />
                    <span class="text-nowrap"> of {{ props.total }} entries </span>
                  </div>
                  <div>
                    <b-pagination
                      :per-page="pageLength"
                      :total-rows="props.total"
                      :value="1"
                      align="right"
                      class="mt-1 mb-0"
                      first-number
                      last-number
                      next-class="next-item"
                      prev-class="prev-item"
                      @input="(value)=>props.pageChanged({currentPage:value})"
                    >
                      <template #prev-text>
                        <feather-icon
                          icon="ChevronLeftIcon"
                          size="18"
                        />
                      </template>
                      <template #next-text>
                        <feather-icon
                          icon="ChevronRightIcon"
                          size="18"
                        />
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template>
            </vue-good-table>
          </b-col>
        </b-row>
        <b-row :class="`${this.modalstage === 2 ? '' : 'd-none'}`">
          <b-col md="12">
            <h5>Selected Startups:</h5>
            <div style="display:flex; flex-wrap: wrap;">
              <b-badge
                v-for="sstartup in selectedStartups"
                :key="sstartup.id"
                variant="primary"
                class="mb-50 mr-50"
              >
                {{ sstartup.users_organizationtable.title }}
              </b-badge>
              <b-badge
                variant="light-primary"
                class="mb-50 mr-50"
                style="cursor: pointer;"
                @click="modalstage=1"
              >Modify</b-badge>
            </div>
            <b-form-group
              label="Request Title"
              label-for="Request Title"
            >
              <b-form-input
                id="requestTitle"
                v-model="request.title"
                placeholder="Request Title"
                type="text"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Description"
              label-for="Description"
            >
              <b-form-textarea
                id="requestTitle"
                v-model="request.description"
                placeholder="Description"
                type="text"
                rows="2"
                max-rows="4"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Priority level"
              label-for="Priority level"
            >
              <v-select
                v-model="request.priority"
                class="text-capitalize"
                :options="['low','medium','high','urgent']"
                placeholder="Choose Priority level"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-modal>
      <b-modal
        v-if="requests[selectedRow] && requests[selectedRow].hasOwnProperty('description') "
        id="requestDetailsModal"
        hide-footer
        :title="requests[selectedRow].title"
      >
        <div>
          <p>
            {{ requests[selectedRow].description }}
          </p>
          <div
            v-if="requests[selectedRow].comments && requests[selectedRow].comments.length"
          >
            <hr>
            <span
              class="font-medium-1"
            >Comments</span>
            <div
              v-for="(comment, index) in requests[selectedRow].comments"
              :key="index"
              class="border-top mt-1"
            >
              <p class="mt-1 font-weight-bold d-flex justify-content-between text-capitalize">
                {{ comment.created_by }}
                <span><feather-icon icon="CalendarIcon" />
                  {{ comment.created_at }}</span>
              </p>
              <p class="pl-50">
                {{ comment.message }}
              </p>
            </div>
          </div>
        </div>
      </b-modal>
    </b-overlay>
  </div>
</template>
<script>

import {
  BBadge,
  BButton,
  BDropdown,
  BDropdownItem,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormTextarea,
  BPagination,
  BFormRadioGroup, BFormCheckbox,
  // BFormCheckbox,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import gql from 'graphql-tag'
import vSelect from 'vue-select'
import { getUserData } from '@/auth/utils'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

export default {
  components: {
    BFormCheckbox,
    BFormRadioGroup,
    BFormInput,
    BFormTextarea,
    BFormGroup,
    BButton,
    VueGoodTable,
    BPagination,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BBadge,
    // BFormCheckbox,
    // Treeselect,
    vSelect,
  },
  data() {
    return {
      searchTerm: '',
      startupsearchTerm: '',
      selectedRow: 0,
      pageLength: 5,
      tab: 1,
      startups: [],
      selectedStartups: [],
      // virtualMeetInvitees: [],
      fetchtype: 'accept',
      modalstage: 1,
      columns: [
        {
          label: 'Request Title',
          field: 'title',
        },
        {
          label: 'Priority',
          field: 'priority',
          tdClass: 'text-capitalize',
        },
        {
          label: 'Requester',
          field(row) {
            if (row.raisedbyincubator) return getUserData().associatedOrgDetails.filter(e => e.role === 'superadmin')[0].users_organizationtable.title
            return row.usersAssociationtableByRequester.users_organizationtable.title
          },
        },
        {
          id: 'assigned_to',
          label: 'Assignee',
          field(row) {
            if (!row.usersAssociationtableByAssignee) return 'Incubator'
            return row.usersAssociationtableByAssignee.users_organizationtable.title
          },
        },
        {
          label: 'Assignee Status',
          field: 'assignee_status',
          tdClass: 'text-capitalize',
        },
        {
          label: 'Created on',
          field(row) {
            return `${new Date(row.created_at)
              .toLocaleString('en-US', {
                month: 'short',
                day: 'numeric',
                year: 'numeric',
                timeZone: 'UTC',
              })}`
          },
        },
        {
          label: 'Status',
          field: 'status',
          tdClass: 'text-capitalize',
          filterOptions: {
            enabled: true,
            placeholder: 'Filter by Status',
            filterDropdownItems: ['assigned', 'unassigned', 'in-process', 'resolved', 'closed'],
          },
        },
      ],
      startup_columns: [
        // {
        //   label: '#',
        //   field: 'action',
        // },
        {
          label: 'Name',
          field(row) {
            return row.users_organizationtable.title
          },
        },
        {
          label: 'Industry',
          field(row) {
            return row.users_organizationtable.industry
          },
        },
      ],
      requester: null,
      requests: [],
      mutationLoading: false,
      request: {
        title: '',
        description: '',
        priority: '',
      },
      assignee: null,
      associations: [
        {
          id: '0',
          label: 'Startups',
          children: [
            {
              id: '0-0',
              label: 'Incubatee',
              children: [],
              isDisabled: true,
            },
            {
              id: '0-1',
              label: 'Graduate',
              children: [],
              isDisabled: true,
            },
            {
              id: '0-2',
              label: 'Network',
              children: [],
              isDisabled: true,
            },
            {
              id: '0-3',
              label: 'Pre-Incubatee',
              children: [],
              isDisabled: true,
            },
          ],
        },
        {
          id: '1',
          label: 'Students',
          children: [
            {
              id: '1-0',
              label: 'In-house',
              children: [],
              isDisabled: true,
            },
            {
              id: '1-1',
              label: 'Network',
              children: [],
              isDisabled: true,
            },
          ],
        },
        {
          id: '2',
          label: 'Mentors',
          children: [
            {
              id: '2-0',
              label: 'In-house',
              children: [],
              isDisabled: true,
            },
            {
              id: '2-1',
              label: 'Network',
              children: [],
              isDisabled: true,
            },
          ],
        },
        {
          id: '3',
          label: 'Partners',
          children: [
            {
              id: '3-0',
              label: 'In-House',
              children: [],
              isDisabled: true,
            },
            {
              id: '3-1',
              label: 'Network',
              children: [],
              isDisabled: true,
            },
          ],
        },
      ],
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        assigned: 'light-secondary',
        unassigned: 'light-warning',
        unchecked: 'light-secondary',
        'in-process': 'light-primary',
        resolved: 'light-success',
        closed: 'light-danger',
        low: 'success',
        medium: 'warning',
        high: 'light-danger',
        urgent: 'danger',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    filteredRows() {
      let { requests } = this
      if (this.requester) {
        requests = requests.filter(r => {
          if (this.requester === 'Incubator') return r.raisedbyincubator
          if (this.requester === 'Startup') return r.raisedbystartup
          return true
        })
      }
      if (this.hideClosed) {
        requests = requests.filter(r => r.status !== 'closed')
      }
      return requests
    },
  },
  methods: {
    selectionChanged(e) {
      this.selectedStartups = e.selectedRows
      // console.log(this.selectedStartups)
    },
    togglestartups(id) {
      if (this.selectedStartups.indexOf(id) >= 0) {
        this.selectedStartups.splice(this.selectedStartups.indexOf(id), 1)
      } else {
        this.selectedStartups.push(id)
      }
    },
    addRequest(modalevent) {
      modalevent.preventDefault()
      if (this.modalstage === 1) {
        if (this.selectedStartups.length === 0) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Please select at least 1 startup',
              icon: 'XIcon',
              variant: 'danger',
            },
          })
        } else {
          this.modalstage = 2
        }
      } else {
        this.mutationLoading = true
        this.$apollo.mutate({
          mutation: gql`
            mutation addRequest($objects: [requesttable_insert_input!]!) {
              insert_requesttable(objects: $objects) {
                affected_rows
              }
            },
          `,
          variables: {
            objects: this.selectedStartups.map(el => ({
              title: this.request.title,
              description: this.request.description,
              priority: this.request.priority,
              incubator_id: getUserData().associatedOrgDetails.filter(e => e.role === 'superadmin')[0].organization_id,
              assignee_id: el.id,
              is_request: true,
              is_partner: true,
              status: 'assigned',
              assignee_status: 'unchecked',
              raisedbyincubator: true,
            })),
          },
        })
          .then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Request added successfully',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            this.request = {
              title: '',
              description: '',
              priority: '',
            }
            this.mutationLoading = false
            this.$apollo.queries.requests.refetch()
            this.$bvModal.hide('addRequestModalp')
          })
          .catch(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error adding request',
                icon: 'XIcon',
                variant: 'danger',
              },
            })
            this.mutationLoading = false
          })
      }
    },
    updateRequestStatus(requestId, status) {
      this.mutationLoading = true
      this.$apollo.mutate({
        mutation: gql`
          mutation updateRequestStatus($requestId: Int!, $status: String!) {
            update_requesttable_by_pk(pk_columns: {requesttable_id: $requestId}, _set: {status: $status}) {
              requesttable_id
            }
          },
        `,
        variables: {
          requestId,
          status,
        },
      })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Request status updated successfully',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.mutationLoading = false
          this.$apollo.queries.requests.refetch()
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error updating request status',
              icon: 'XIcon',
              variant: 'danger',
            },
          })
          this.mutationLoading = false
        })
    },
    updateRequestAssigneeStatus(requestId, status) {
      this.mutationLoading = true
      this.$apollo.mutate({
        mutation: gql`
          mutation updateRequestAssigneeStatus($requestId: Int!, $status: String!) {
            update_requesttable_by_pk(pk_columns: {requesttable_id: $requestId}, _set: {assignee_status: $status}) {
              requesttable_id
            }
          },
        `,
        variables: {
          requestId,
          status,
        },
      })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Assignee status updated successfully',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.mutationLoading = false
          this.$apollo.queries.requests.refetch()
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error updating request assignee status',
              icon: 'XIcon',
              variant: 'danger',
            },
          })
          this.mutationLoading = false
        })
    },
    updateRequestAssignee(requestId, assigneeId) {
      this.mutationLoading = true
      this.$apollo.mutate({
        mutation: gql`
          mutation updateRequestAssignee($requestId: Int!, $assigneeId: Int!) {
            update_requesttable_by_pk(pk_columns: {requesttable_id: $requestId}, _set: {assignee_id: $assigneeId, assignee_status: "unchecked"}) {
              requesttable_id
            }
          },
        `,
        variables: {
          requestId,
          assigneeId,
        },
      })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Request assigned successfully',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.mutationLoading = false
          this.assignee = null
          this.$apollo.queries.requests.refetch()
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error updating assignee',
              icon: 'XIcon',
              variant: 'danger',
            },
          })
          this.mutationLoading = false
          this.assignee = null
        })
    },
  },
  apollo: {
    requests: {
      query() {
        return gql`query requestsp {
          requesttable(where: {is_request: {_eq: true}, is_partner: {_eq: true}, incubator_id: {_eq: ${getUserData().associatedOrgDetails.filter(e => e.role === 'superadmin')[0].organization_id}}}, order_by: {requesttable_id: desc}) {
            requesttable_id
            title
            description
            priority
            incubator_id
            assignee_id
            is_request
            is_mentor
            status
            assignee_status
            raisedbystartup
            raisedbymentor
            raisedbyincubator
            created_at
            usersAssociationtableByRequester {
              users_organizationtable {
                title
              }
            }
            usersAssociationtableByAssignee {
              users_organizationtable {
                title
              }
            }
          }
        }`
      },
      update: data => data.requesttable,
    },
    startups: {
      query() {
        return gql`query startupsp {
          users_associationtable(where: {incubator_id: {_eq: ${getUserData().associatedOrgDetails.filter(e => e.role === 'superadmin')[0].organization_id}}, role: {_eq: "partner"}, status: {${this.fetchtype != null ? `_eq: "${this.fetchtype}"` : '_is_null: true'}}, program_id: {_is_null: true}}) {
            id
            users_organizationtable {
              title
              industry
            }
          }
        }`
      },
      update: data => data.users_associationtable,
    },

    associations: {
      query() {
        return gql`
        query startup_associations($incubator_id: Int!) {
          users_associationtable(where: {incubator_id: {_eq: $incubator_id}}) {
            id
            role
            status
            users_customuser {
              full_name
            }
            users_organizationtable {
              title
            }
          }
        }`
      },
      variables: {
        incubator_id: getUserData()
          .associatedOrgDetails
          .filter(e => e.role === 'superadmin')[0].organization_id,
      },
      update(data) {
        const { associations } = this
        data.users_associationtable.forEach(e => {
          if (e.role === 'partner') {
            associations[3].children[e.status === 'accept' ? 0 : 1].children.push({
              label: e.users_organizationtable.title,
              id: e.id,
            })
            associations[3].children[e.status === 'accept' ? 0 : 1].isDisabled = false
          } else if (e.role === 'mentor') {
            associations[2].children[e.status === 'accept' ? 0 : 1].children.push({
              label: e.users_customuser.full_name,
              id: e.id,
            })
            associations[2].children[e.status === 'accept' ? 0 : 1].isDisabled = false
          } else if (e.role === 'student') {
            associations[1].children[e.status === 'accept' ? 0 : 1].children.push({
              label: e.users_customuser.full_name,
              id: e.id,
            })
            associations[1].children[e.status === 'accept' ? 0 : 1].isDisabled = false
          } else if (e.role === 'incubatee') {
            associations[0].children[0].children.push({
              label: e.users_organizationtable.title,
              id: e.id,
            })
            associations[0].children[0].isDisabled = false
          } else if (e.role === 'graduate') {
            associations[0].children[1].children.push({
              label: e.users_organizationtable.title,
              id: e.id,
            })
            associations[0].children[1].isDisabled = false
          } else if (e.role === 'startup') {
            associations[0].children[2].children.push({
              label: e.users_organizationtable.title,
              id: e.id,
            })
            associations[0].children[2].isDisabled = false
          } else if (e.role === 'pre-incubatee') {
            associations[0].children[3].children.push({
              label: e.users_organizationtable.title,
              id: e.id,
            })
            associations[0].children[3].isDisabled = false
          }
        })
        return associations
      },
    },

  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>

<style>
.selected-top-tab{
  background-color: #7367F0;
  color: #fff;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  font-weight: bolder;
  cursor: pointer;
}
.deselected-top-tab{
  background-color: #F1F1F3;
  color: #000;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  font-weight: bolder;
  cursor: pointer;
}
</style>
